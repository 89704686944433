'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _createRule = require('../utils/createRule');

var _createRule2 = _interopRequireDefault(_createRule);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var toCssOptions = { indent: 1 };

var KeyframeRule = function () {
  function KeyframeRule(selector, frames, options) {
    _classCallCheck(this, KeyframeRule);

    this.type = 'keyframe';

    this.selector = selector;
    this.options = options;
    this.frames = this.formatFrames(frames);
  }

  /**
   * Creates formatted frames where every frame value is a rule instance.
   */


  _createClass(KeyframeRule, [{
    key: 'formatFrames',
    value: function formatFrames(frames) {
      var newFrames = Object.create(null);
      for (var name in frames) {
        var options = _extends({}, this.options, {
          parent: this,
          className: name,
          selector: name
        });
        var rule = (0, _createRule2['default'])(name, frames[name], options);
        options.jss.plugins.onProcessRule(rule);
        newFrames[name] = rule;
      }
      return newFrames;
    }

    /**
     * Generates a CSS string.
     */

  }, {
    key: 'toString',
    value: function toString() {
      var str = this.selector + ' {\n';
      for (var name in this.frames) {
        str += this.frames[name].toString(toCssOptions) + '\n';
      }
      str += '}';
      return str;
    }
  }]);

  return KeyframeRule;
}();

exports['default'] = KeyframeRule;